import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"

const ThreeUpBlocks = ({ blocks }) => {  
  return (
    <div className="threeup-blocks landmarklet">
      <div className="grid-wrapper grid-wrapper--full">
        {blocks.map( (block, index) => {
          return (
            <div key={index} className="grid lap-one-third">
              <Img fluid={block.image.localFile.childImageSharp.fluid} />
              <div className="block-content">
                <h3>{block.title}</h3>
                <p dangerouslySetInnerHTML={{__html: block.description}} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

ThreeUpBlocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ThreeUpBlocks
