import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import { Carousel } from "react-responsive-carousel"

const HeroCarousel = ({ heroImages }) => {
  return (
    <Carousel className="hero-carousel" autoPlay infiniteLoop transitionTime={500} showStatus={false} showThumbs={false}>
      {heroImages.map( (image, index) => {
        let sources = [
          image.heroImage.localFile.childImageSharp.mobileImage,
          {
            ...image.heroImage.localFile.childImageSharp.desktopImage,
            media: `(min-width: 676px)`,
          },
        ]
        return (
          <div key={index}>
            <Img fluid={sources} />
          </div>
        )
      })}
    </Carousel>
  )
}

HeroCarousel.propTypes = {
  heroImages: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default HeroCarousel
