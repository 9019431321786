import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Header from "../components/Header"
import Footer from "../components/Footer"
import HeroCarousel from "../components/HeroCarousel"
import InfoBar from "../components/InfoBar"
import LoungeGrid from "../components/LoungeGrid"
import LocationMap from "../components/LocationMap"
import ThreeUpBlocks from "../components/ThreeUpBlocks"
import Legal from "../components/Legal"

export default ({ location, data }) => {
  const page = data.strapiCenturionHomepage
  const locations = data.allStrapiTheCenturionLounge.nodes
  return (
    <Layout>
      <SEO metaTitle={page.metaTitle} />
      <Header location={location} />
      <div className="homepage">
        {page.infoBar &&
          <InfoBar content={page.infoBar} />
        }
        {page.heroImages &&
          <HeroCarousel heroImages={page.heroImages} />
        }
        <div className="head hidden-below-lap">
          <div className="head-box">
            <h1>{page.title}</h1>
          </div>
        </div>
        <div className="container container--med island text-center">
          <div className="intro" dangerouslySetInnerHTML={{__html: page.intro}} />
        </div>
      </div>
      {locations &&
        <LoungeGrid locations={locations} additionalGridItems={page.additionalGridItems} />
      }
      <LocationMap />
      {page.threeUpBlocks &&
        <ThreeUpBlocks blocks={page.threeUpBlocks} />
      }
      <Legal />
      <Footer footnote={true} />
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiCenturionHomepage {
      metaTitle
      title
      heroImages {
        heroImage {
          localFile {
            childImageSharp {
              desktopImage:fluid(maxWidth: 1200, maxHeight: 420) {
                ...GatsbyImageSharpFluid
              }
              mobileImage:fluid(maxWidth: 676, maxHeight: 540) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      intro
      infoBar
      additionalGridItems {
        title
        gridBackgroundImage{
        localFile {
          childImageSharp {
            fluid(maxWidth: 676) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
        linkUrl
        newWindow
      }
      threeUpBlocks {
       	image {
         localFile {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
        title
        description
      }
    }
    allStrapiTheCenturionLounge(
      filter: {
        showInListing: { eq: true }
        isInternational: { eq: false }
      }
      sort: {
        fields: [shortAirportName]
        order: ASC
      }
    ) {
      nodes {
        airportCode
        shortAirportName
        showInListing
        gridBackgroundImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 676) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`