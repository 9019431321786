import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

function Legal({ content }) {
  const { strapiCenturionGlobal } = useStaticQuery(
    graphql`
      query {
        strapiCenturionGlobal {
          legal
        }
      }
    `
  )
  const legal = content || strapiCenturionGlobal.legal

  return (
    <div className="legal">
      {legal &&
        <div className="container container--med text-center" dangerouslySetInnerHTML={{__html: legal}} />
      }
    </div>
  )
}

Legal.propTypes = {
  content: PropTypes.string,
}

export default Legal
