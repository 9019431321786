import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import BackgroundImage from 'gatsby-background-image'
import { Carousel } from "react-responsive-carousel"

export default class LoungeGrid extends React.Component {
  constructor() {
    super()
    this.state = { isOpen: false }
  }

  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  render() {
    const { locations, additionalGridItems } = this.props
    return (
      <>
        <div className={this.state.isOpen ? 'location-grid open': 'location-grid'}>
          <Carousel centerMode showArrows={false} showStatus={false} showIndicators={false} showThumbs={false}>
            {locations.map( (location, index) => {
              return (
                <div key={index}>
                  <BackgroundImage
                    Tag="div"
                    className="location-card"
                    fluid={location.gridBackgroundImage.localFile.childImageSharp.fluid}
                  >
                    <div className="location-card-overlay"></div>
                    <div className="location-card-content">
                      <h3 className="h2">{location.shortAirportName} <br />({location.airportCode})</h3>
                      <Link to={"/locations/" + location.airportCode.toLowerCase() + "/"} className="btn white">Explore Lounge</Link>
                    </div>
                  </BackgroundImage>
                </div>
              )
            })}
            {additionalGridItems && additionalGridItems.map( (location, index) => {
              return (
                <div key={index}>
                  <BackgroundImage
                    Tag="div"
                    className="location-card"
                    fluid={location.gridBackgroundImage.localFile.childImageSharp.fluid}
                  >
                    <div className="location-card-overlay"></div>
                    <div className="location-card-content">
                      <h3 className="h2">{location.title}</h3>
                      {location.newWindow &&
                        <a href={location.linkUrl} className="btn white" target="_blank" rel="noopener noreferrer">Explore Lounges</a>
                      }
                      {!location.newWindow &&
                        <Link to={location.linkUrl} className="btn white">Explore Lounges</Link>
                      }
                    </div>
                  </BackgroundImage>
                </div>
              )
            })}
          </Carousel>
        </div>
        <div className="location-grid-btn island text-center">
          <button className="btn" onClick={this.toggleOpen}>{this.state.isOpen ? 'View Less': 'View More'}</button>
        </div>
      </>
    )
  }
}

LoungeGrid.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  additionalGridItems: PropTypes.arrayOf(PropTypes.object)
}
