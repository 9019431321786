import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

function InfoBar({ content, inLounge }) {
  const { strapiCenturionGlobal } = useStaticQuery(
    graphql`
      query {
        strapiCenturionGlobal {
          infoBar
          inLoungeInfoBar
        }
      }
    `
  )
  const globalInfoBar = inLounge ? strapiCenturionGlobal.inLoungeInfoBar : strapiCenturionGlobal.infoBar
  const infoBar = content || globalInfoBar

  return (
    infoBar &&
      <div className="info-bar" dangerouslySetInnerHTML={{__html: infoBar}} />
  )
}

InfoBar.propTypes = {
  content: PropTypes.string,
  inLounge: PropTypes.bool,
}

export default InfoBar
